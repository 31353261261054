import "../../css/App.css";
import HeaderOfSegmentTable from "./HeaderOfSegmentTable";
import { getSegmentList, updateSegment } from "../../fsrc/fsrc";
import { useState } from "react";
import { Link } from "react-router-dom";

const Segmentation = (props) => {
  const [segmentName, setSegmentName] = useState(undefined);
  const [segmentArray, setSegmentArray] = useState(false);
  const [alertInfo, setAlertInfo] = useState({ show: false, text: "Hi" });

  const handleInputChange = (event, id, field) => {
    const { value } = event.target;
    setSegmentArray((prevElements) => {
      let newData = prevElements.data.map((el) => {
        return el.segment_row === id ? { ...el, [field]: Number(value) } : el;
      });
      let newArray = { data: newData };
      return newArray;
    });
  };

  function onInputSegmentValueChange(event) {
    setSegmentName(event.target.value);
  }

  function updateSegmentButton() {
    if (segmentName === "" || segmentName === undefined) {
      alert("Введите сегмент!");
    } else {
      try {
        setAlertInfo({
          show: true,
          text: `Обновление ${segmentName}...`,
        });

        updateSegment(segmentArray.data).then((json) => {
          if (json.status === "OK") {
            setAlertInfo({
              show: true,
              text: `Сегмент ${segmentName} успешно обновлен!`,
            });
            setTimeout(() => {
              setAlertInfo({ show: false, text: "Hi" });
            }, 4000);
          } else {
            setAlertInfo({
              show: true,
              text: `Произошла ошибка при обновлении ${segmentName} попробуйте снова!`,
            });
            setTimeout(() => {
              setAlertInfo({ show: false, text: "Hi" });
            }, 4000);
          }
        });
      } catch (error) {
        setAlertInfo({
          show: true,
          text: `Произошла ошибка на сервере.`,
        });
        setTimeout(() => {
          setAlertInfo({ show: false, text: "Hi" });
        }, 4000);
      }
    }
  }

  async function getSegmentInfo() {
    await getSegmentList(segmentName).then(async (array) => {
      if (array.data.length > 0) {
        await setSegmentArray(array);
      } else {
        setAlertInfo({
          show: true,
          text: `Данных по сегменту ${segmentName} нет в базе данных!`,
        });
        setTimeout(() => {
          setAlertInfo({ show: false, text: "Hi" });
        }, 4000);
      }
    });
  }

  return (
    <div className="segmentMainDive">
      {alertInfo.show && (
        <dialog
          style={{
            position: "fixed",
            top: "30%",
            left: "30%",
            transform: "translateX(-50%)",
            padding: "20px",
            backgroundColor: "#fff",
            border: "none",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            maxWidth: "90%",
            width: "400px",
            textAlign: "center",
          }}
          open
        >
          {alertInfo.text}
        </dialog>
      )}
      <div className="segmentNextDivAfterMainLeft">
        <HeaderOfSegmentTable theText="Название сегмента" />
        <input
          className="segment-input-in-segment"
          type="text"
          id={"00"}
          title={"Название сегмента"}
          onInput={onInputSegmentValueChange}
        />
        <div className="segmentbtn-new-container">
          <button
            className="segmentbtn-new"
            type="button"
            onClick={getSegmentInfo}
          >
            Загрузить сегмент
          </button>
          <button
            className="segmentbtn-new"
            type="button"
            onClick={updateSegmentButton}
          >
            Сохранить
          </button>
          <Link to="/segmentation" className="segmentbtn-new">
            <div>Добавление сегмента</div>
          </Link>
        </div>
      </div>
      <div className="segmentNextDivAfterMainRight">
        <div className="segmetOneRow">
          <HeaderOfSegmentTable theText="Максимальный РРЦ для нормы прибыли" />
          <HeaderOfSegmentTable theText="Норма прибыли для данной РРЦ" />
          <HeaderOfSegmentTable theText="Показатель неценки к РРЦ, не более" />
          <HeaderOfSegmentTable theText="Мин. цена" />
          <HeaderOfSegmentTable theText="Макс. цена" />
          <HeaderOfSegmentTable theText="Процент возврата" />
        </div>

        {segmentArray
          ? segmentArray.data.map((el) => {
              return (
                <div className="segmetOneRow">
                  <input
                    className="input-in-segment"
                    type="number"
                    id={el.segment_row}
                    value={el.max_rrc}
                    onInput={(e) => {
                      handleInputChange(e, el.segment_row, "max_rrc");
                    }}
                  />
                  <input
                    className="input-in-segment"
                    type="number"
                    id={el.segment_row}
                    value={el.income_norm}
                    onInput={(e) => {
                      handleInputChange(e, el.segment_row, "income_norm");
                    }}
                  />
                  <input
                    className="input-in-segment"
                    type="number"
                    id={el.segment_row}
                    value={el.rrc_max_diff}
                    onInput={(e) => {
                      handleInputChange(e, el.segment_row, "rrc_max_diff");
                    }}
                  />
                  <input
                    className="input-in-segment"
                    type="number"
                    id={el.segment_row}
                    value={el.min_price}
                    onInput={(e) => {
                      handleInputChange(e, el.segment_row, "min_price");
                    }}
                  />
                  <input
                    className="input-in-segment"
                    type="number"
                    id={el.segment_row}
                    value={el.max_price}
                    onInput={(e) => {
                      handleInputChange(e, el.segment_row, "max_price");
                    }}
                  />
                  <input
                    className="input-in-segment"
                    type="number"
                    id={el.segment_row}
                    value={el.proc_vikypa}
                    onInput={(e) => {
                      handleInputChange(e, el.segment_row, "proc_vikypa");
                    }}
                  />
                </div>
              );
            })
          : () => {
              return <div>Loading...</div>;
            }}
      </div>
    </div>
  );
};

export default Segmentation;
