import React from "react";
import { Link } from "react-router-dom";
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      placeholder: "Drop - X",
    };
  }
  text = "Увеличить счетчик";
  render() {
    return (
      <header className="App-header">
        <Link to="/*" className="HomePageLink">
          <div>{this.state.placeholder}</div>
        </Link>
      </header>
    );
  }
}

export default Header;
