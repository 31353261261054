import React, { useEffect, useState } from "react";
import Table from "rc-table";
import Dropdown from "react-dropdown";
import TableCSS from "rc-table/assets/index.css";
import { getSendedInfoData, getBrandList } from "../../fsrc/fsrc";

const SegmentDBVisual = (props) => {
  // Initial state definitions
  const [options, setOptions] = useState([" - ", "Загрузка..."]);
  const [filter, setFilter] = useState({
    sku: undefined,
    brand: undefined,
    diss: undefined,
    priority: undefined,
  });
  const [paginationStep, setPaginationStep] = useState(1000);
  const [totalNumb, setTotalNumb] = useState(0);
  const [data, setData] = useState([
    {
      sku: "Загрузка...",
      sku_brand: "Загрузка...",
      calculated_price: "Загрузка...",
      sku_seller_name: "Загрузка...",
      sales_decision: "Загрузка...",
      income_rubbles: "Загрузка...",
      income_percent: "Загрузка...",
      delta_rashod_mp: "Загрузка...",
      delta_dolya_sebesa: "Загрузка...",
      priority: "Загрузка...",
      rrc: "Загрузка...",
      promotional_price: "Загрузка...",
      markup_to_mrc: "Загрузка...",
    },
  ]);

  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  // Function to fetch data initially
  function fetching() {
    getSendedInfoData(0).then((array) => {
      if (array.data === null || array.data === undefined) {
        fetching();
      } else {
        setData(array.data);
        setTotalNumb(array.total);
      }
    });
  }

  // Function to fetch brand list
  function fetchBrands() {
    getBrandList().then((array) => {
      if (array === null || array === undefined) {
        fetchBrands();
      } else {
        setOptions(array);
      }
    });
  }

  // useEffect to fetch data on mount
  useEffect(() => {
    fetching();
    fetchBrands();
  }, []);

  // Function to handle sorting
  const handleSort = (fieldName) => {
    let direction = "asc";
    if (sortConfig.key === fieldName && sortConfig.direction === "asc") {
      direction = "desc";
    }

    setSortConfig({ key: fieldName, direction });

    const sortedData = [...data].sort((a, b) => {
      if (a[fieldName] < b[fieldName]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[fieldName] > b[fieldName]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    setData(sortedData);
  };

  // Define columns with sorting capability
  const columns = [
    {
      title: (
        <span
          onClick={() => handleSort("sku_seller_name")}
          style={{ cursor: "pointer" }}
        >
          Аккаунт{" "}
          {sortConfig.key === "sku_seller_name" &&
            (sortConfig.direction === "asc" ? "↑" : "↓")}
        </span>
      ),
      dataIndex: "sku_seller_name",
      key: "sku_seller_name",
      width: 100,
    },
    {
      title: (
        <span
          onClick={() => handleSort("sku_brand")}
          style={{ cursor: "pointer" }}
        >
          Бренд{" "}
          {sortConfig.key === "sku_brand" &&
            (sortConfig.direction === "asc" ? "↑" : "↓")}
        </span>
      ),
      dataIndex: "sku_brand",
      key: "sku_brand",
      width: 100,
    },
    {
      title: (
        <span onClick={() => handleSort("sku")} style={{ cursor: "pointer" }}>
          Артикул{" "}
          {sortConfig.key === "sku" &&
            (sortConfig.direction === "asc" ? "↑" : "↓")}
        </span>
      ),
      dataIndex: "sku",
      key: "sku",
      width: 100,
    },
    {
      title: (
        <span
          onClick={() => handleSort("sales_decision")}
          style={{ cursor: "pointer" }}
        >
          Решение системы{" "}
          {sortConfig.key === "sales_decision" &&
            (sortConfig.direction === "asc" ? "↑" : "↓")}
        </span>
      ),
      dataIndex: "sales_decision",
      key: "sales_decision",
      width: 60,
    },
    {
      title: (
        <span
          onClick={() => handleSort("priority")}
          style={{ cursor: "pointer" }}
        >
          Приоритет{" "}
          {sortConfig.key === "priority" &&
            (sortConfig.direction === "asc" ? "↑" : "↓")}
        </span>
      ),
      dataIndex: "priority",
      key: "priority",
      width: 60,
    },
    {
      title: (
        <span
          onClick={() => handleSort("calculated_price")}
          style={{ cursor: "pointer" }}
        >
          Полученная цена{" "}
          {sortConfig.key === "calculated_price" &&
            (sortConfig.direction === "asc" ? "↑" : "↓")}
        </span>
      ),
      dataIndex: "calculated_price",
      key: "calculated_price",
      width: 100,
    },
    {
      title: (
        <span onClick={() => handleSort("rrc")} style={{ cursor: "pointer" }}>
          МРЦ{" "}
          {sortConfig.key === "rrc" &&
            (sortConfig.direction === "asc" ? "↑" : "↓")}
        </span>
      ),
      dataIndex: "rrc",
      key: "rrc",
      width: 100,
    },
    {
      title: (
        <span
          onClick={() => handleSort("promotional_price")}
          style={{ cursor: "pointer" }}
        >
          Акционная цена{" "}
          {sortConfig.key === "promotional_price" &&
            (sortConfig.direction === "asc" ? "↑" : "↓")}
        </span>
      ),
      dataIndex: "promotional_price",
      key: "promotional_price",
      width: 100,
    },
    {
      title: (
        <span
          onClick={() => handleSort("markup_to_mrc")}
          style={{ cursor: "pointer" }}
        >
          Наценка на МРЦ{" "}
          {sortConfig.key === "markup_to_mrc" &&
            (sortConfig.direction === "asc" ? "↑" : "↓")}
        </span>
      ),
      dataIndex: "markup_to_mrc",
      key: "markup_to_mrc",
      width: 100,
    },
    {
      title: (
        <span
          onClick={() => handleSort("income_rubbles")}
          style={{ cursor: "pointer" }}
        >
          Доход в рублях{" "}
          {sortConfig.key === "income_rubbles" &&
            (sortConfig.direction === "asc" ? "↑" : "↓")}
        </span>
      ),
      dataIndex: "income_rubbles",
      key: "income_rubbles",
      width: 100,
    },
    {
      title: (
        <span
          onClick={() => handleSort("income_percent")}
          style={{ cursor: "pointer" }}
        >
          Доход в процентах{" "}
          {sortConfig.key === "income_percent" &&
            (sortConfig.direction === "asc" ? "↑" : "↓")}
        </span>
      ),
      dataIndex: "income_percent",
      key: "income_percent",
      width: 100,
    },
    {
      title: (
        <span
          onClick={() => handleSort("delta_rashod_mp")}
          style={{ cursor: "pointer" }}
        >
          Расход МП{" "}
          {sortConfig.key === "delta_rashod_mp" &&
            (sortConfig.direction === "asc" ? "↑" : "↓")}
        </span>
      ),
      dataIndex: "delta_rashod_mp",
      key: "delta_rashod_mp",
      width: 100,
    },
    {
      title: (
        <span
          onClick={() => handleSort("delta_dolya_sebesa")}
          style={{ cursor: "pointer" }}
        >
          Доля Закупа{" "}
          {sortConfig.key === "delta_dolya_sebesa" &&
            (sortConfig.direction === "asc" ? "↑" : "↓")}
        </span>
      ),
      dataIndex: "delta_dolya_sebesa",
      key: "delta_dolya_sebesa",
      width: 100,
    },
  ];

  function loadMore() {
    getSendedInfoData(
      paginationStep,
      filter.sku,
      filter.brand,
      filter.diss,
      filter.priority
    ).then((array) => {
      if (array.data === null || array.data === undefined) {
        fetching();
      } else {
        let buffer = data.slice();
        let buffer2 = buffer.concat(array.data);
        setData(buffer2);
        setPaginationStep(paginationStep + 1000);
        setTotalNumb(array.total);
      }
    });
  }

  function useFilters() {
    getSendedInfoData(
      0,
      filter.sku,
      filter.brand,
      filter.diss,
      filter.priority
    ).then((array) => {
      if (array.data === null || array.data === undefined) {
        fetching();
      } else {
        setData(array.data);
        setTotalNumb(array.total);
      }
    });
  }

  function getValueFromInput(event) {
    if (event.target.value === "") {
      filter[event.target.getAttribute("data_key")] = undefined;
    } else {
      filter[event.target.getAttribute("data_key")] = event.target.value.trim();
    }
  }

  function brandChoes(event) {
    filter["brand"] = event.value === " - " ? undefined : event.value.trim();
  }

  function dissChoes(event) {
    filter["diss"] = event.value === " - " ? undefined : event.value.trim();
  }

  function priorityChoes(event) {
    filter["priority"] = event.value === " - " ? undefined : event.value.trim();
  }

  return (
    <div className="LastSendedDataMainDiv">
      <div className="lastsendedDataFilters">
        <div className="lastsendedDataFiltersDivs">
          <div>Артикул</div>
          <input
            className="segment-input-in-segment"
            type="text"
            key="sku"
            data_key="sku"
            onInput={getValueFromInput}
          />
        </div>
        <div className="lastsendedDataFiltersDivs">
          <div>Бренд</div>
          <Dropdown
            options={options}
            placeholder="Бренд"
            className="dropdownMenu"
            menuClassName="dropdownMenuOpen"
            onChange={brandChoes}
          />
        </div>
        <div className="lastsendedDataFiltersDivs">
          <div>Решение</div>
          <Dropdown
            options={[" - ", "Да", "Нет"]}
            placeholder="Решение"
            className="dropdownMenu"
            menuClassName="dropdownMenuOpen2"
            onChange={dissChoes}
          />
        </div>
        <div className="lastsendedDataFiltersDivs">
          <div>Приоритет</div>
          <Dropdown
            options={[" - ", "1", "2", "3", "4"]}
            placeholder="Приоритет"
            className="dropdownMenu"
            menuClassName="dropdownMenuOpen3"
            onChange={priorityChoes}
          />
        </div>
        <div className="lastsendedDataFiltersDivs">
          <button
            className="lastsendedDataButton"
            type="button"
            onClick={useFilters}
          >
            Применить
          </button>
        </div>
      </div>
      <div style={{ width: 100, marginBottom: "3px" }}>Всего: {totalNumb}</div>
      <Table
        columns={columns}
        data={data}
        className="table"
        sticky={true}
        scroll={{ x: 13, y: 500 }}
      />
      <button className="lastsendedDataButton" type="button" onClick={loadMore}>
        Загрузить еще
      </button>
    </div>
  );
};

export default SegmentDBVisual;
