import "../css/App.css";
import { Link } from "react-router-dom";
import { sendDataToVamsvetServer } from "../fsrc/fsrc";
import { useEffect, useState } from "react";

const HomePage = (props) => {
  const [displayPop, setDisplayPop] = useState(false);

  async function sendData() {
    const result = await sendDataToVamsvetServer();
  }

  function showAlert() {
    setDisplayPop(true);
    setTimeout(() => {
      setDisplayPop(false);
    }, 4000);
  }
  return (
    <div className="div-for-form">
      <Link to="/sellerInfo" className="HomePageLink">
        Информация о селлере
      </Link>
      <Link to="/segmentation" className="HomePageLink">
        Информация сегментациях
      </Link>
      <Link to="/lastdownloads" className="HomePageLink">
        Данные последней выгрузки
      </Link>
      <Link to="/bordpage" className="HomePageLink">
        Блок схема
      </Link>
      <Link to="/sendedlogger" className="HomePageLink">
        Лог отправки
      </Link>
      <button
        className="HomePageLink"
        type="button"
        onDoubleClick={sendData}
        onClick={showAlert}
      >
        Ручная отправка данных
      </button>
      <dialog open={displayPop}>
        Для отправки данных на сервер подрядчика используйте двойной клик на
        кнопку.
      </dialog>
    </div>
  );
};

export default HomePage;
