import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { checkAuth } from "../../fsrc/fsrc";

const ProtectedRoute = ({ element: Component, ...rest }) => {
  let [auth, setAuth] = useState(false);
  let [loading, setLoading] = useState(true);
  let token = localStorage.getItem("token");

  useEffect(() => {
    const fetching = async () => {
      try {
        const resp = await checkAuth(token);
        setAuth(resp);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetching();
  }, []);
  //const auth = checkAuth(token); //auth
  return loading ? (
    <div>Loading...</div>
  ) : auth ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;
