import React, { useEffect, useState } from "react";
import Table from "rc-table";
import TableCSS from "rc-table/assets/index.css";
import { getSegmentInfoData } from "../../fsrc/fsrc";

const SegmentDBVisual = (props) => {
  const columns = [
    {
      title: "Название сегмента",
      dataIndex: "segment_name",
      key: "segment_name",
      width: 200,
    },
    {
      title: "Максимальный МРЦ сегмента",
      dataIndex: "max_rrc",
      key: "max_rrc",
      width: 100,
    },
    {
      title: "Норма прибыли",
      dataIndex: "income_norm",
      key: "income_norm",
      width: 100,
    },
    {
      title: "Разрешенная разница с МРЦ",
      dataIndex: "rrc_max_diff",
      key: "rrc_max_diff",
      width: 100,
    },
    {
      title: "Максимальная цена",
      dataIndex: "max_price",
      key: "max_price",
      width: 100,
    },
    {
      title: "Минимальная цена",
      dataIndex: "min_price",
      key: "min_price",
      width: 100,
    },
    {
      title: "Процент возврата",
      dataIndex: "proc_vikypa",
      key: "proc_vikypa",
      width: 100,
    },
  ];

  const [data, setData] = useState([
    {
      segment_name: "Загрузка",
      max_rrc: "Загрузка",
      income_norm: "Загрузка",
      rrc_max_diff: "Загрузка",
    },
  ]);

  function fetching() {
    getSegmentInfoData().then((array) => {
      if (array === null || array === undefined) {
        fetching();
      } else {
        setData(array);
      }
      console.log(array);
    });
  }

  useEffect(() => {
    fetching();
  }, []);

  return (
    <div className="LastSendedDataMainDiv">
      <Table
        columns={columns}
        data={data}
        className="table"
        scroll={{ x: 8, y: 600 }}
        sticky={true}
      />
    </div>
  );
};

export default SegmentDBVisual;
