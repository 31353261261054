import React, { useEffect, useState } from "react";
import {
  getSellerPropertiesFromDataBase,
  putDataToDataBase,
} from "../fsrc/fsrc";

function SellerInfoForm(props) {
  const [data, setData] = useState([]);
  const [fetchingInProgress, setFetchingInProgress] = useState(false);
  const [infoAboutGettedData, setInfoAboutGettedData] = useState(false);
  const [dialogText, setdialogText] = useState("");

  function fetching() {
    getSellerPropertiesFromDataBase().then((array) => {
      if (array === null || array === undefined) {
        fetching();
      } else {
        array.map((el) => {
          el.newValue = "-";
        });
        setData(array);
      }
      console.log(array);
    });

    setFetchingInProgress(false);
  }

  useEffect(() => {
    fetching();
  }, []);

  let dataArray = [];
  if (Array.isArray(data)) {
    data.forEach((element) => {
      dataArray.push({
        id: Number(element.id),
        value: Number(element.value),
        title: String(element.title),
        newValue: "-",
      });
    });
  }

  function buttonGetData(event) {
    if (fetchingInProgress) {
      return;
    } else {
      setFetchingInProgress(true);
      if (dataArray.length === 0) {
        setdialogText("Загрузка не удалась");
      } else {
        putDataToDataBase(dataArray).then(() => {
          setInfoAboutGettedData(true);
          setdialogText("Загрузка прошла успешно!");
          setTimeout(() => {
            setInfoAboutGettedData(false);
          }, 2000);
        });
        setTimeout(fetching, 1500);
      }

      return;
    }
  }

  function foobar() {
    return;
  }

  function getValueFromInput(event) {
    let isExist = dataArray.findIndex((index) => {
      return index.id === Number(event.target.id);
    });
    if (isExist === -1) {
      dataArray.push({
        id: Number(event.target.id),
        title: String(event.target.title),
        value: Number(event.target.value),
        newValue: Number(event.target.newValue),
      });
    } else {
      if (event.target.value === "") {
        dataArray[isExist].newValue = "-";
      } else {
        dataArray[isExist].newValue = Number(event.target.value);
      }
    }
    console.log(dataArray);
  }

  return (
    <div className="div-for-form">
      <dialog open={infoAboutGettedData}>{dialogText}</dialog>
      <form className="App-settingTableUnitForm">
        <div className="App-settingTableUnit">
          <div className="div-in-form-first">Показатели</div>
          <div className="div-in-form">Ввод</div>
          <div className="div-in-form">Текущие</div>
        </div>
        {Array.isArray(data) ? (
          data.map((el) => (
            <div className="App-settingTableUnit" key={el.id}>
              <div className="div-in-form-first">{el.title}</div>
              <div className="div-in-form">
                <input
                  className="input-in-form"
                  type="number"
                  id={el.id}
                  title={el.title}
                  onInput={getValueFromInput}
                  placeholder={el.title}
                />
              </div>

              <div className="div-in-form">
                {el.value}
                {el.title.includes("%")
                  ? "%"
                  : el.title.includes("шт")
                  ? " шт"
                  : el.title.includes("руб")
                  ? " руб"
                  : ""}
              </div>
            </div>
          ))
        ) : (
          <div>Loading...</div>
        )}
      </form>
      <button
        className="btn-new"
        type="button"
        onClick={() => buttonGetData(1)}
        onDoubleClick={() => foobar(1)}
      >
        Сохранить
      </button>
    </div>
  );
}

export default SellerInfoForm;
