import React, { useState } from "react";
import { login } from "../../fsrc/fsrc";
import { Navigate } from "react-router-dom";
import "../../css/App.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [novig, setNovig] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await login({ username, password }).then((resp) => {
        localStorage.setItem("token", resp.accessToken);
      });
      setNovig(true);
    } catch (err) {
      alert("Не верный логин или пароль");
      setNovig(false);
    }
  };

  return novig ? (
    <Navigate to="/*" />
  ) : (
    <form onSubmit={handleSubmit} className="loginPageDiv">
      <input
        className="loginPageInput"
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        className="loginPageInput"
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button className="loginButton" type="submit">
        Login
      </button>
    </form>
  );
};

export default Login;
