// Bordpage.js
import React, { useState, useRef, useEffect } from "react";
import ReactFlow, { Controls, Background } from "react-flow-renderer";
import "./Bordpage.css";
import { getAllInfoAboutSku } from "../../fsrc/fsrc";

const modalStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10000,
  },
  content: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    width: "400px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    zIndex: 10001,
    whiteSpace: "pre-wrap",
    textAlign: "left",
  },
};

const Modal = ({ onClose, children }) => {
  const modalRef = useRef();

  useEffect(() => {
    // Close the modal when clicking outside of it
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    // Add event listener to listen for clicks
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div style={modalStyles.overlay}>
      <div ref={modalRef} style={modalStyles.content}>
        {children}
      </div>
    </div>
  );
};

const Bordpage = () => {
  const initialNodesDefault = [
    {
      id: "counterField",
      data: {
        label: `Место для расчетов`,
      },
      position: { x: 1100, y: 150 },
      className: "labelWrap",
      style: { width: "600px" },
    },
    //info field
    {
      id: "info1",
      data: {
        label: `Данные из 1С:
  _______
  Артикул поставщика
  Наименование
  Бренд
  Кабинет
  Категория МП
  Сбс
  МРЦ
  Акционная Цена
  Объем`,
      },
      position: { x: 350, y: -250 },
      className: "labelWrap",
    },
    {
      id: "info2",
      data: {
        label: `Ценовая сегментация:
  _______
  Норма прибыли
  Предмет
  Наценка н/б в МРЦ
  Минимум цена
  Максимум цена
  % возврата`,
      },
      position: { x: 575, y: -250 },
      className: "labelWrap",
    },
    {
      id: "info3",
      data: {
        label: `Исходные настройки:
  _______
  План транзакций
  Фикс ОР
  Перменные ОР
  Налог
  % ЧП
  Целевой % закупа
  Целевой % расходов МП
  Кол-во положительных решений
  Приоритет цены`,
      },
      position: { x: 800, y: -250 },
      className: "labelWrap",
    },

    // First row nodes
    {
      id: "1",
      data: { label: "Получение ЦЕНА_1 (новая прибыль руб.)" },
      position: { x: 600, y: 50 },
      className: "oval-node", // Oval node for "Получение ЦЕНА_2"
    },
    // Second row nodes
    {
      id: "2",
      data: { label: "Проходит порог по % ЧП?" },
      position: { x: 625, y: 200 },
      className: "rhombus-node", // Rhombus node for "Проходит порог по % ЧП?"
    },
    {
      id: "3",
      data: { label: "Получение ЦЕНА_2" },
      position: { x: 300, y: 210 },
      className: "oval-node", // Oval node for "Получение ЦЕНА_2"
    },
    // Third row nodes
    {
      id: "4",
      data: { label: "ЦЕНА_1 ниже МРЦ?" },
      position: { x: 625, y: 350 },
      className: "rhombus-node", // Rhombus node for "Проходит порог по % ЧП?"
    },
    {
      id: "5",
      data: { label: "ЦЕНА_2 ниже МРЦ?" },
      position: { x: 325, y: 350 },
      className: "rhombus-node", // Rhombus node for "Проходит порог по % ЧП?"
    },
    {
      id: "6",
      data: { label: "Наценка на МРЦ выше заданой нормы?" },
      position: { x: 775, y: 350 },
      className: "rhombus-node", // Rhombus node for "Проходит порог по % ЧП?"
    },
    {
      id: "7",
      data: { label: "Наценка на МРЦ выше заданой нормы?" },
      position: { x: 175, y: 350 },
      className: "rhombus-node", // Rhombus node for "Проходит порог по % ЧП?"
    },
    // 4th row
    {
      id: "8",
      data: { label: "Акционная цена? или Нет МРЦ?" },
      position: { x: 325, y: 500 },
      className: "rhombus-node", // Rhombus node for "Проходит порог по % ЧП?"
    },
    {
      id: "9",
      data: { label: "Акционная цена? или Нет МРЦ?" },
      position: { x: 625, y: 500 },
      className: "rhombus-node", // Rhombus node for "Проходит порог по % ЧП?"
    },
    // 5th row
    {
      id: "10",
      data: { label: "ФИН_ЦЕНА = ЦЕНА_2" },
      position: { x: 25, y: 650 },
      className: "rhombus-node-circled", // Rhombus node for "Проходит порог по % ЧП?"
    },
    {
      id: "11",
      data: { label: "ФИН_ЦЕНА = ЦЕНА_2" },
      position: { x: 175, y: 650 },
      className: "rhombus-node-circled", // Rhombus node for "Проходит порог по % ЧП?"
    },
    {
      id: "12",
      data: { label: "ФИН_ЦЕНА = ЦЕНА_2" },
      position: { x: 325, y: 650 },
      className: "rhombus-node-circled", // Rhombus node for "Проходит порог по % ЧП?"
    },
    {
      id: "13",
      data: { label: "ФИН_ЦЕНА = МРЦ" },
      position: { x: 475, y: 650 },
      className: "rhombus-node-circled", // Rhombus node for "Проходит порог по % ЧП?"
    },
    {
      id: "14",
      data: { label: "ФИН_ЦЕНА = ЦЕНА_1" },
      position: { x: 625, y: 650 },
      className: "rhombus-node-circled", // Rhombus node for "Проходит порог по % ЧП?"
    },
    {
      id: "15",
      data: { label: "ФИН_ЦЕНА = ЦЕНА_1" },
      position: { x: 775, y: 650 },
      className: "rhombus-node-circled", // Rhombus node for "Проходит порог по % ЧП?"
    },
    {
      id: "16",
      data: { label: "ФИН_ЦЕНА = ЦЕНА_1" },
      position: { x: 925, y: 650 },
      className: "rhombus-node-circled", // Rhombus node for "Проходит порог по % ЧП?"
    },
    // 6th row
    {
      id: "pr2",
      data: { label: "ПР 2" },
      position: { x: 495, y: 800 },
      className: "circle-node", // Circular node for PR1
    },
    {
      id: "pr1",
      data: { label: "ПР 1" },
      position: { x: 495, y: 900 },
      className: "circle-node", // Circular node for PR1
    },
    {
      id: "pr3",
      data: { label: "ПР 3" },
      position: { x: 495, y: 1000 },
      className: "circle-node", // Circular node for PR1
    },
    {
      id: "pr4",
      data: { label: "ПР 4" },
      position: { x: 495, y: 1100 },
      className: "circle-node", // Circular node for PR1
    },
    // 7th row
    {
      id: "17",
      data: { label: "Доля расходов МП выше плана?" },
      position: { x: 475, y: 1200 },
      className: "rhombus-node", // Rhombus node for "Проходит порог по % ЧП?"
    },
    {
      id: "18",
      data: { label: "Решение системы НЕТ" },
      position: { x: 300, y: 1200 },
      className: "rhombus-node-circled", // Rhombus node for "Проходит порог по % ЧП?"
    },
    {
      id: "19",
      data: { label: "Решение системы ДА" },
      position: { x: 650, y: 1200 },
      className: "rhombus-node-circled", // Rhombus node for "Проходит порог по % ЧП?"
    },
    // 8th row
    {
      id: "20",
      data: { label: "Доля себеса выше плана?" },
      position: { x: 475, y: 1350 },
      className: "rhombus-node", // Rhombus node for "Проходит порог по % ЧП?"
    },
    {
      id: "21",
      data: { label: "Решение системы НЕТ" },
      position: { x: 300, y: 1350 },
      className: "rhombus-node-circled", // Rhombus node for "Проходит порог по % ЧП?"
    },
    {
      id: "22",
      data: { label: "Решение системы ДА" },
      position: { x: 650, y: 1350 },
      className: "rhombus-node-circled", // Rhombus node for "Проходит порог по % ЧП?"
    },
    // 9th row
    {
      id: "23",
      data: { label: "Кол-во положительных решений меньше плана?" },
      position: { x: 475, y: 1500 },
      className: "rhombus-node", // Rhombus node for "Проходит порог по % ЧП?"
    },
    {
      id: "24",
      data: { label: "Финальное решение НЕТ" },
      position: { x: 300, y: 1500 },
      className: "rhombus-node-circled", // Rhombus node for "Проходит порог по % ЧП?"
    },
    {
      id: "25",
      data: { label: "Финальное решение ДА" },
      position: { x: 650, y: 1500 },
      className: "rhombus-node-circled", // Rhombus node for "Проходит порог по % ЧП?"
    },
    {
      id: "26",
      data: { label: "Приоритет цены соответсвует настройкам?" },
      position: { x: 650, y: 1700 },
      className: "rhombus-node", // Rhombus node for "Проходит порог по % ЧП?"
    },
    {
      id: "27",
      data: { label: "Готово к отправке" },
      position: { x: 350, y: 1925 },
      className: "oval-node", // Oval node for "Получение ЦЕНА_2"
    },
    {
      id: "proceBlock",
      data: { label: "Проходит порог цены?" },
      position: { x: 650, y: 1900 },
      className: "rhombus-node", // Rhombus node for "Проходит порог по % ЧП?"
    },
  ];

  const initialEdgesDefault = [
    // Edges connecting nodes
    {
      id: "e1-2",
      source: "1",
      target: "2",
      type: "smoothstep",
      animated: false,
    },
    {
      id: "e2-3",
      source: "2",
      target: "3",
      type: "smoothstep",
      animated: false,
      label: "Нет",
    },
    {
      id: "e2-4",
      source: "2",
      target: "4",
      type: "smoothstep",
      animated: false,
      label: "Да",
    },
    { id: "e3-5", source: "3", target: "5", type: "default", animated: false },
    {
      id: "e4-6",
      source: "4",
      target: "6",
      type: "smoothstep",
      animated: false,
      label: "Нет",
    },
    {
      id: "e5-7",
      source: "5",
      target: "7",
      type: "smoothstep",
      animated: false,
      label: "Нет",
    },
    {
      id: "e5-8",
      source: "5",
      target: "8",
      type: "smoothstep",
      animated: false,
      label: "Да",
    },
    {
      id: "e4-9",
      source: "4",
      target: "9",
      type: "smoothstep",
      animated: false,
      label: "Да",
    },
    {
      id: "e7-10",
      source: "7",
      target: "10",
      type: "default",
      animated: false,
      label: "Да",
    },
    {
      id: "e7-11",
      source: "7",
      target: "11",
      type: "default",
      animated: false,
      label: "Нет",
    },
    {
      id: "e8-12",
      source: "8",
      target: "12",
      type: "default",
      animated: false,
      label: "Да",
    },
    {
      id: "e8-13",
      source: "8",
      target: "13",
      type: "default",
      animated: false,
      label: "Нет",
    },
    {
      id: "e9-13",
      source: "9",
      target: "13",
      type: "default",
      animated: false,
      label: "Нет",
    },
    {
      id: "e9-14",
      source: "9",
      target: "14",
      type: "default",
      animated: false,
      label: "Да",
    },
    {
      id: "e6-15",
      source: "6",
      target: "15",
      type: "default",
      animated: false,
      label: "Нет",
    },
    {
      id: "e6-16",
      source: "6",
      target: "16",
      type: "default",
      animated: false,
      label: "Да",
    },
    {
      id: "e13-pr2",
      source: "13",
      target: "pr2",
      type: "default",
      animated: false,
    },
    {
      id: "e14-pr1",
      source: "14",
      target: "pr1",
      type: "default",
      animated: false,
    },
    {
      id: "e12-pr1",
      source: "12",
      target: "pr1",
      type: "default",
      animated: false,
    },
    {
      id: "e11-pr3",
      source: "11",
      target: "pr3",
      type: "straight",
      animated: false,
    },
    {
      id: "e15-pr3",
      source: "15",
      target: "pr3",
      type: "straight",
      animated: false,
    },
    {
      id: "e10-pr4",
      source: "10",
      target: "pr4",
      type: "straight",
      animated: false,
    },
    {
      id: "e16-pr4",
      source: "16",
      target: "pr4",
      type: "straight",
      animated: false,
    },
    {
      id: "epr4-17",
      source: "pr4",
      target: "17",
      type: "smoothstep",
      animated: false,
    },
    {
      id: "e17-18",
      source: "17",
      target: "18",
      type: "smoothstep",
      animated: false,
      label: "Да",
    },
    {
      id: "e17-19",
      source: "17",
      target: "19",
      type: "smoothstep",
      animated: false,
      label: "Нет",
    },
    {
      id: "e18-20",
      source: "18",
      target: "20",
      type: "smoothstep",
      animated: false,
    },
    {
      id: "e19-20",
      source: "19",
      target: "20",
      type: "smoothstep",
      animated: false,
    },
    {
      id: "e20-21",
      source: "20",
      target: "21",
      type: "smoothstep",
      animated: false,
      label: "Да",
    },
    {
      id: "e20-22",
      source: "20",
      target: "22",
      type: "smoothstep",
      animated: false,
      label: "Нет",
    },
    {
      id: "e21-23",
      source: "21",
      target: "23",
      type: "smoothstep",
      animated: false,
    },
    {
      id: "e22-23",
      source: "22",
      target: "23",
      type: "smoothstep",
      animated: false,
    },
    {
      id: "e23-24",
      source: "23",
      target: "24",
      type: "smoothstep",
      animated: false,
      label: "Да",
    },
    {
      id: "e23-25",
      source: "23",
      target: "25",
      type: "smoothstep",
      animated: false,
      label: "Нет",
    },
    {
      id: "e25-26",
      source: "25",
      target: "26",
      type: "smoothstep",
      animated: false,
    },
    {
      id: "e26-pB",
      source: "26",
      target: "proceBlock",
      type: "smoothstep",
      animated: false,
      label: "Да",
    },
    {
      id: "e24-26",
      source: "24",
      target: "26",
      type: "default",
      animated: false,
      label: "Нет",
    },
    {
      id: "epB-27",
      source: "proceBlock",
      target: "27",
      type: "smoothstep",
      animated: false,
      label: "Да",
    },
    {
      id: "e24-pB",
      source: "24",
      target: "proceBlock",
      type: "default",
      animated: false,
      label: "Нет",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [initialNodes, setInitialNodes] = useState(initialNodesDefault);

  const [initialEdges, setInitialEdges] = useState(initialEdgesDefault);

  const [info, setInfo] = useState({});
  const [skuInput, setSkuInput] = useState({});

  // Function to handle node click to open the modal
  const handleNodeClick = () => {
    setIsModalOpen(true);
  };

  // Function to handle modal close
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  async function getTheSolution() {
    setInitialEdges(initialEdgesDefault);
    setInitialNodes(initialNodesDefault);
    await getAllInfoAboutSku(skuInput).then((info) => {
      setInfo(info);
      if (info === null || info.unitInfo === undefined) {
        alert("Введите артикул или проверьте на правильность написания!");
      } else {
        visualisationOfData(info);
      }
    });
  }

  function visualisationOfData(info) {
    updateNodeById("counterField", {
      data: {
        label: `Расчеты:
  1) Цена:
  ${
    info.solvedValues.priority === 2
      ? `МРЦ - ${info.solvedValues.fineshedPrice} р.`
      : `${info.solvedValues.priceFormula} = ${info.solvedValues.fineshedPrice} р.`
  }
  2) Чистая прибыль:
  Формула: ${info.solvedValues.finishedIncome.formulas.incomRub.general} 

  Расчет: ${info.solvedValues.finishedIncome.formulas.incomRub.withValues}  = ${
          info.solvedValues.finishedIncome.incomRub
        }`,
      },
    });
    updateNodeById("info1", {
      data: {
        label: `Данные из 1С:
  _______
  Артикул поставщика - ${info.unitInfo.unit.unit.sku}
  Наименование - ${info.unitInfo.unit.unit.sku_name}
  Бренд - ${info.unitInfo.unit.unit.sku_brand}
  Кабинет - ${info.unitInfo.unit.unit.sku_seller_name}
  Категория МП - "${info.unitInfo.unit.unit.sku_category}"
  Сбс - ${info.unitInfo.unit.unit.last_prime_cost}
  МРЦ - ${info.unitInfo.unit.unit.rrc}
  Акционная Цена - ${info.unitInfo.unit.unit.promotional_price}
  Объем - ${info.unitInfo.unit.unit.volume}`,
      },
    });

    updateNodeById("info2", {
      data: {
        label: `Ценовая сегментация:
  _______
  Норма прибыли - ${info.unitInfo.incomeInfo.incomeNorm}
  Предмет - "${info.unitInfo.unit.unit.sku_category}"
  Наценка н/б в МРЦ - ${info.unitInfo.incomeInfo.incomeDiff}
  Минимум цена - ${info.unitInfo.incomeInfo.minSegmentPrice}
  Максимум цена - ${info.unitInfo.incomeInfo.maxSegmentPrice}
  % возврата - ${info.unitInfo.incomeInfo.procentVozvrata}`,
      },
    });

    updateNodeById("info3", {
      data: {
        label: `Исходные настройки:
  _______
  План транзакций - ${info.sellerInfo.planOfTransactions}шт.
  Фикс ОР - ${info.sellerInfo.operacionnieRashodiAll}р.
  Перменные ОР - ${info.sellerInfo.operacionnieRashodiPercent}%
  Налог - ${info.sellerInfo.nalog}%
  Целевой % ЧП - ${info.sellerInfo.normaIncom}%
  Целевой % закупа - ${info.sellerInfo.targetDolyaZakupa}%
  Целевой % рас-дов МП - ${info.sellerInfo.targetRashodMP}%
  Кол-во положительных решений - ${info.sellerInfo.numOfPositivConclusions}
  Приоритет цены - ${info.sellerInfo.choesenpPriority}`,
      },
    });

    updateNodeById("1", {
      data: {
        label: (
          <div
            onClick={() => {
              handleNodeClick();
            }}
            style={{ cursor: "pointer" }}
          >
            {info.solvedValues.firstPrice.countedPrice} р.
          </div>
        ),
      },
    });

    makeEdgeGreen("e1-2");

    if (info.whatPrice === "first") {
      makeEdgeGreen("e2-4");

      if (info.solvedValues.priceLessThenMrc) {
        //Цена 1 ниже МРЦ?
        makeEdgeGreen("e4-9"); // Да
        if (info.solvedValues.actionPriceOrMRC) {
          //Акционная цена? или Нет МРЦ?
          makeEdgeGreen("e9-14"); //Да
          updateNodeById("14", {
            data: {
              label: `${info.solvedValues.fineshedPrice} р.`,
            },
          });
          makeEdgeGreen("e14-pr1");
          choesenNodeStyle("pr1");
        } else {
          makeEdgeGreen("e9-13"); //Нет
          updateNodeById("13", {
            data: {
              label: `${info.solvedValues.fineshedPrice} р.`,
            },
          });
          makeEdgeGreen("e13-pr2");
          choesenNodeStyle("pr2");
        }
      } else {
        makeEdgeGreen("e4-6"); // Нет
        if (!info.solvedValues.comparationMrc) {
          //Наценка на МРЦ выше заданной нормы?
          makeEdgeGreen("e6-16"); //Да
          updateNodeById("16", {
            data: {
              label: `${info.solvedValues.fineshedPrice} р.`,
            },
          });
          makeEdgeGreen("e16-pr4");
          choesenNodeStyle("pr4");
        } else {
          makeEdgeGreen("e6-15"); //Нет
          updateNodeById("15", {
            data: {
              label: `${info.solvedValues.fineshedPrice} р.`,
            },
          });
          makeEdgeGreen("e15-pr3");
          choesenNodeStyle("pr3");
        }
      }
    } else if (info.whatPrice === "second") {
      makeEdgeGreen("e2-3");

      updateNodeById("3", {
        data: {
          label: `${info.solvedValues.secondPrice.countedPrice} р.`,
        },
      });

      makeEdgeGreen("e3-5");

      if (info.solvedValues.priceLessThenMrc) {
        //Цена 2 ниже МРЦ?
        makeEdgeGreen("e5-8"); // Да
        if (info.solvedValues.actionPriceOrMRC) {
          //Акционная цена? или Нет МРЦ?
          makeEdgeGreen("e8-12");
          updateNodeById("12", {
            data: {
              label: `${info.solvedValues.fineshedPrice} р.`,
            },
          });
          makeEdgeGreen("e12-pr1");
          choesenNodeStyle("pr1");
        } else {
          makeEdgeGreen("e8-13");
          updateNodeById("13", {
            data: {
              label: `${info.solvedValues.fineshedPrice} р.`,
            },
          });
          makeEdgeGreen("e13-pr2");
          choesenNodeStyle("pr2");
        }
      } else {
        makeEdgeGreen("e5-7"); // Нет
        if (!info.solvedValues.comparationMrc) {
          //Наценка на МРЦ выше заданной нормы?
          makeEdgeGreen("e7-10"); //Да
          updateNodeById("10", {
            data: {
              label: `${info.solvedValues.fineshedPrice} р.`,
            },
          });
          makeEdgeGreen("e10-pr4");
          choesenNodeStyle("pr4");
        } else {
          makeEdgeGreen("e7-11"); //Нет
          updateNodeById("11", {
            data: {
              label: `${info.solvedValues.fineshedPrice} р.`,
            },
          });
          makeEdgeGreen("e11-pr3");
          choesenNodeStyle("pr3");
        }
      }
    }

    //Из приориета 4
    makeEdgeGreen("epr4-17");
    if (info.decisions.rashodMPDecizion) {
      //Доля расходов МП выше плана?
      //Нет
      makeEdgeGreen("e17-19");
      choesenNodeStyle("19");
      makeEdgeGreen("e19-20");
    } else {
      //Да
      makeEdgeGreen("e17-18");
      choesenNodeStyle("18");
      makeEdgeGreen("e18-20");
    }

    if (info.decisions.dolyaSBSDecizion) {
      //Доля себеса выше плана?
      //Нет
      makeEdgeGreen("e20-22");
      choesenNodeStyle("22");
      makeEdgeGreen("e22-23");
    } else {
      //Да
      makeEdgeGreen("e20-21");
      choesenNodeStyle("21");
      makeEdgeGreen("e21-23");
    }

    if (info.solvedValues.numOfPositiveDisizionsMoreOrEqlPlan) {
      //Кол-во положительныйх решений меньше плана?
      //Нет
      makeEdgeGreen("e23-25");
      choesenNodeStyle("25");
      makeEdgeGreen("e25-26");
      if (info.solvedValues.passesThePriorityTest) {
        makeEdgeGreen("e24-26");
        choesenNodeStyle("24");
      } else {
        makeEdgeGreen("e26-pB");
        updateNodeById("proceBlock", {
          data: {
            label: `Мин - ${info.unitInfo.incomeInfo.minSegmentPrice} Макс - ${info.unitInfo.incomeInfo.maxSegmentPrice}`,
          },
        });

        if (
          info.solvedValues.fineshedPrice >
            info.unitInfo.incomeInfo.minSegmentPrice &&
          info.solvedValues.fineshedPrice <
            info.unitInfo.incomeInfo.maxSegmentPrice
        ) {
          makeEdgeGreen("epB-27");
          choesenNodeStyle("27");
        } else {
          makeEdgeGreen("e24-pB");
        }
      }
    } else {
      //Да
      makeEdgeGreen("e23-24");
      choesenNodeStyle("24");
    }

    function makeEdgeGreen(edgeId) {
      updateEdgeById(edgeId, {
        style: { stroke: "green", strokeWidth: 3 },
      });
    }

    function choesenNodeStyle(nodeId) {
      updateNodeById(nodeId, {
        style: {
          "background-color": "#ecea75",
          border: "3px solid green",
        },
      });
    }
  }

  function inputChage(event) {
    setSkuInput(event.target.value.trim());
  }

  function updateNodeById(nodeId, newProperties) {
    setInitialNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId ? { ...node, ...newProperties } : node
      )
    );
  }

  function updateEdgeById(nodeId, newProperties) {
    setInitialEdges((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId ? { ...node, ...newProperties } : node
      )
    );
  }

  return (
    <div className="bordpage-container">
      <input
        className="BordpageInputsDiv"
        placeholder="Артикул"
        onInput={inputChage}
      ></input>
      <button className="bpButton" onClick={getTheSolution}>
        Расчет
      </button>
      <ReactFlow
        nodes={initialNodes}
        edges={initialEdges}
        fitView
        style={{ width: "100%", height: "90vh" }}
      >
        <Controls />
        <Background contentEditable={false} />
      </ReactFlow>
      {isModalOpen && (
        <Modal onClose={handleCloseModal}>
          <h2>Расчет цены</h2>
          <p>
            {info.solvedValues.firstPrice.formula.split("Расчет")[0]}Расчет
            {info.solvedValues.firstPrice.formula.split("Расчет")[1]}=
            {info.solvedValues.firstPrice.countedPrice}
          </p>
          <button onClick={handleCloseModal}>Закрыть</button>
        </Modal>
      )}
    </div>
  );
};

export default Bordpage;
